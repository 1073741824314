import React, { useEffect, useState } from 'react';
import Category from './category.js';

const fetchJson = async (url) => {
  const res = await fetch(url, { Accept: 'application/json' });

  const data = await res.json();
  return data;
};

const Map = ({ data, selected, setSelected, hideTitle = false }) => {
  const [groupedData, setGroupedData] = useState([]);
  const [categoryOrdering, setCategoryOrdering] = useState([]);

  function groupByCategory(data) {
    return data.reduce((acc, item) => {
      if (!acc[item.Category]) {
        acc[item.Category] = [];
      }
      acc[item.Category].push(item);
      return acc;
    }, {});
  }

  /**
   * Takes into account the case where a category is not explicitly listed
   * in the ordering. If thats the case we append the category to the end.
   *
   * Also fills in categories if an error occured during fetching ordering.
   *
   * @returns {string[]} the list of all ordered categories.
   */
  const compileAllOrderedCategories = () => {
    const orderedCategories = [...categoryOrdering];

    Object.keys(groupedData).forEach((category) => {
      if (!orderedCategories.includes(category)) {
        orderedCategories.push(category);
      }
    });

    return orderedCategories;
  };

  useEffect(() => {
    if (data) {
      let tempData = groupByCategory(data);
      setGroupedData(tempData);
    }

    fetchJson(process.env.REACT_APP_ORDERINGS_URL)
      .then((ordering) => {
        setCategoryOrdering(ordering.categories);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [data, hideTitle]);

  return (
    <div className="bg-[#5603AD] rounded-[24px] md:rounded-[44px] flex flex-col container color-[#202020] w-full max-w-6xl max-h-[calc(100vh-180px)]">
      {hideTitle ? null : (
        <h1 className="text-4xl p-[32px] text-[#F0FFF1]">Bittensor Subnets</h1>
      )}
      {hideTitle ? null : (
        <hr className="border-[#F0FFF1] border-1 opacity-20" />
      )}
      {/* Container for categories */}
      <div className="columns-1 md:columns-2 gap-1 space-y-1 p-[12px] md:p-[32px] overflow-y-scroll overflow-x-hidden mx-2">
        {compileAllOrderedCategories().map((category, categoryIndex) => {
          if (groupedData[category] === undefined) {
            console.warn(
              `Category "${category}" defined in ordering.json, but does not exist in subnets.csv`
            );
            return null;
          }

          return (
            <Category
              key={categoryIndex}
              category={category}
              subnets={groupedData[category]}
              selected={selected}
              setSelected={setSelected}
            />
          );
        })}
        <br />
      </div>
    </div>
  );
};

export default Map;
